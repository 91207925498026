module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"livingspace","accessToken":"MC5YeDZfZEJFQUFDVUFxZ1Zk.77-9f3d1Xu-_vWcKTg7vv71b77-977-9Gzbvv73vv73vv73vv73vv73vv70oUnpAQC9lTCBT","path":"/preview","previews":false,"pages":[{"type":"Homepage","match":"/:uid","path":"/preview/homepages-type-preview","component":"/opt/build/repo/src/templates/home.js"},{"type":"Home_type","match":"/:uid","path":"/preview/home-type-preview","component":"/opt/build/repo/src/templates/home_type.js"},{"type":"Development","match":"/development/:uid","path":"/preview/development-preview","component":"/opt/build/repo/src/templates/development.js"},{"type":"Standard_page","match":"/:uid","path":"/preview/standard-page-type-preview","component":"/opt/build/repo/src/templates/page.js"},{"type":"Contact_page","match":"/:uid","path":"/preview/contact-page-type-preview","component":"/opt/build/repo/src/templates/contact.js"},{"type":"Project","match":"/project/:uid","path":"/preview/project-page-type-preview","component":"/opt/build/repo/src/templates/project.js"},{"type":"Article","match":"news/:uid","path":"/preview/article-page-type-preview","component":"/opt/build/repo/src/templates/article.js"},{"type":"Basic_page","match":"/:uid","path":"/preview/basic-page-type-preview","component":"/opt/build/repo/src/templates/basic_page.js"},{"type":"Search_page","match":"/:uid","path":"/preview/search-page-type-preview","component":"/opt/build/repo/src/templates/search.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-130196525-2","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"pageTransitionDelay":0,"defer":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TP2MRZX","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"915177785663852"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
